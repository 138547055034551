.header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    background-color: #ffffff;
    opacity: 1;
    z-index: 5;
    box-shadow: 2px 4px rgba(128, 128, 128, 0.09);
    border-top: 1px solid gainsboro;
}

.username {
    margin-right: auto;
    margin-left: 7px;
    color: black;
}

.usertext {
    color: darkorange;
}

/* burger menu */

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 1.5rem;
    height: 1.5rem;
    right: 1rem;
    top: .8rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #e19f00;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    top: 0;
}

/* General sidebar styles */
.bm-menu{
    background: #373a47;
    padding: 2.5em 1.5em 2rem;
    font-size: 1.15em;
    height: 90%;
    width: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: rgba(55, 58, 71, 0.18);
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    cursor: pointer;
    padding-bottom: 10px;
}

.bm-item:hover {
    color: white;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(100, 100, 100, 0.76);
}

.menu-item {
    color: #b8b7ad;
    text-decoration: none;
}

.menu-item:hover {
    color: white;
    cursor: pointer;
}

.cart-btn {
    margin: 0 50px 0 auto;
    cursor: pointer;
    position: relative;
}

.cart-badge {
    background-color: darkorange;
    position: absolute;
    top: -5px;
    right: -10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    color: white;
    font-size: 6px;
    padding: 3px 2px 2px 5px;
}
