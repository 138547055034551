.list-wrapper {
    padding-top: 3rem;
}

.list {
    display: grid;
    grid-template-columns: repeat(2, 170px);
    grid-gap: 10px;
    margin: 0 auto;
    width: 345px;
}

.category {
    text-align: center;
    grid-column: 1/3;
    margin: 2rem auto 1rem;
    color: white;
}

