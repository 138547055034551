.product {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    width: 170px;
    position: relative;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19);
}

img {
    width: 100%;
    background: lightgray;
    height: 150px;
    margin: 0 auto;
    background: var(--tg-theme-button-color);
    border-radius: 5px;
}

.title {
    height: 40px;
    text-align: center;
    font-size: 12px;
    color: darkorange;
}

.description-price-container {
    display: grid;
    grid-template-columns: 100px 40px;
    grid-gap: 10px;
    padding-left: 3px;
}

.description {
    font-size: 0.5em;
    color: gray;
    height: 58px;
    min-height: fit-content;
    overflow: hidden;
}

.price-tag {
    align-self: center;
    font-size: 0.8rem;
}

.count-badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #e16500;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 15px;
}

.count-badge-hidden {
    display: none;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
}

