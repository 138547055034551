* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Verdana, Arial, sans-serif;
}

body {
    background-image: url("assets/bg.jpg");
    /*color: var(--tg-theme-text-color);*/
    position: relative;
}

body::before{
    background-color: black;
    content: '';
    opacity: 0.4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.App {
    align-items: center;
}

:target::before {
    content: "";
    display: block;
    height: 70px;
}
