.cart-product {
    display: grid;
    grid-template-columns: 100px 140px 40px 100px;
    margin: 10px auto 10px;
    height: 100px;
}

.cart-product > *:nth-child(2){
    padding: 5px;
    overflow: hidden;

}
.cart-product > *:nth-child(3){
    padding: 20px 5px 5px;
}
.cart-product > *:nth-child(4){
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px 30px;
}

.cart-header {
    color: white;
}

.cart-img img {
    width: 94px;
    height: 94px;
    /*padding: 3px 3px 0;*/
    border-radius: 7px;
    position: relative;
    top: 3px;
    left: 3px;
}

.cart-img {
    z-index: -1;
    background-color: #fff;
    border-radius: 7px;
    position: relative;
}

.cart-product .button-container .count {
    padding: 10px;
    grid-column: 1/3;
    height: 50px;
}

.cart-product .title {
    color: darkorange;
    text-align: left;
    padding-top: 0;
    padding-left: 10px;
    font-size: 1rem;
    height: 100%;
}

.title .description {
    color: white;
    font-size: 0.4rem;
}

.button-container .count {
    color: white;
    font-size: 25px;
}

.cart-product .price {
    color: #ce8527;
    align-self: end;
    margin-bottom: 0;
}

.cart-product .price .price-tag {
    float: right;
}

.cart-product .button {
    width: 50px;
    height: 35px;
    border: none;
}

.sum {
    color: white;
}
